import { ComponentProps } from 'react'

import { SiteLocale } from '../graphql/datoSchema.generated'
import { Page } from '../graphql/page'
import { getPageTitle } from '../pages/[[...slugs]]'
import { usePageContext } from '../utils/PageContext'
import resolveUrlForRecord from '../utils/resolveUrlForRecord'
import Seo from './Seo'

interface Props {
  page: Page
  canonical?: ComponentProps<typeof Seo>['canonical']
  alternates?: ComponentProps<typeof Seo>['alternates']
}

const PageSeo = ({ page, canonical, alternates }: Props) => {
  const { siteConfig } = usePageContext()

  return (
    <Seo
      title={getPageTitle(page)}
      description={page.seo?.description || undefined}
      image={
        page.seo?.image
          ? {
              src: page.seo?.image.url,
              alt: page.seo?.image.alt || undefined,
            }
          : undefined
      }
      twitterCard={page.seo?.twitterCard || undefined}
      canonical={canonical}
      alternates={alternates}
      paths={(page.allSlugs || []).reduce((obj, { locale, value }) => {
        if (locale && value) {
          // eslint-disable-next-line no-param-reassign
          obj[locale] = resolveUrlForRecord(
            siteConfig,
            {
              __typename: 'PageRecord',
              slug: value,
            },
            locale,
          )
        }
        return obj
      }, {} as Record<SiteLocale, string>)}
    />
  )
}

export default PageSeo
