import { GetStaticPaths, GetStaticProps } from 'next'

import { SiteLocale } from '../graphql/datoSchema.generated'
import stripUndefined from '../utils/stripUndefined'
import BlockZone, { normalizeTypename } from '../components/BlockZone'
import blocks from '../blocks'
import { blocksGetStaticProps } from '../blocks/index.server'
import notEmpty from '../utils/notEmpty'
import Layout from '../components/Layout'
import { getLayout, Layout as LayoutType } from '../graphql/layout'
import { getSiteConfig, SiteConfig } from '../graphql/siteConfig'
import { getPage, Page } from '../graphql/page'
import { getPagesList } from '../graphql/pagesList'
import {
  get404Slug,
  get500Slug,
  getHomepageSlug,
  getLocales,
  getSiteId,
} from '../utils/env'
import getAllPages from '../utils/getAllPages'
import PageSeo from '../components/PageSeo'

export interface StaticProps {
  siteConfig: SiteConfig
  layout: LayoutType
  page: Page
  // [typename]: { [propName]: unknown }
  blockData: Record<string, Record<string, unknown>>
  breadcrumbs: { path: string; page?: Page }[]
}

const hardcodedSlugs = [get404Slug(), get500Slug(), 'search']
const getCmsPageSlugs = async () => {
  const siteId = getSiteId()

  return (
    (
      await Promise.all(
        getLocales().map(async (locale) => {
          const siteConfig = await getSiteConfig(siteId, locale)
          const pagePages = await getAllPages(
            (page) =>
              getPagesList(
                siteId,
                locale,
                [
                  ...hardcodedSlugs,
                  siteConfig.newsIndex?.slug,
                  siteConfig.expertsIndex?.slug,
                ].filter(notEmpty),
                100,
                page,
              ),
            100,
          )
          const pages = pagePages.map((page) => page.pages).flat()
          return pages.map(({ slug }) => ({
            slug: slug === getHomepageSlug() ? '' : slug,
            locale,
          }))
        }),
      )
    )
      .flat()
      // Remove pages without valid slug (this should never happen since it's required in the CMS)
      .filter(
        (page): page is typeof page & { slug: string } =>
          page.slug !== undefined && page.slug !== null,
      )
  )
}

export const getStaticPaths: GetStaticPaths = async () => {
  if (process.env.FEATUREFLAG_STATICPATHS !== 'true') {
    return {
      paths: [],
      fallback: 'blocking',
    }
  }

  return {
    paths: (await getCmsPageSlugs()).map(({ slug, locale }) => ({
      params: { slugs: slug.split('/') },
      locale,
    })),
    fallback: 'blocking',
  }
}

const getBreadcrumbs = async (
  siteId: string,
  locale: SiteLocale,
  slugs: string[] = [],
) => {
  const breadcrumbs = await Promise.all(
    slugs.map(async (_, index) => {
      const path: string = slugs
        .slice(0, index + 1)
        .join('/')
        .toLowerCase()
      return {
        path,
        page: await getPage(siteId, locale as SiteLocale, path),
      }
    }),
  )
  return breadcrumbs.filter((breadcrumb) => breadcrumb.page)
}

export const getStaticProps: GetStaticProps<
  StaticProps,
  { slugs: string | string[] }
> = async ({ params, locale }) => {
  if (!locale) {
    throw new Error('Next.js is misconfigured: locale is missing')
  }

  const siteId = getSiteId()
  const slugPath = Array.isArray(params?.slugs)
    ? params?.slugs?.join('/').toLowerCase()
    : params?.slugs?.toLowerCase()
  const slug = slugPath ?? ''

  const [siteConfig, layout, page] = await Promise.all([
    getSiteConfig(siteId, locale as SiteLocale),
    getLayout(siteId, locale as SiteLocale),
    getPage(siteId, locale as SiteLocale, slug || getHomepageSlug()),
  ])

  if (!page) {
    return {
      notFound: true,
      // TODO: Configure via environment variables
      revalidate: 60,
    }
  }

  const breadcrumbs =
    params && typeof params.slugs !== 'string'
      ? await getBreadcrumbs(siteId, locale as SiteLocale, params.slugs)
      : undefined

  const blockData = (
    await Promise.all(
      page.content.map(async (block) => {
        const typename = normalizeTypename(block.__typename)
        const getStaticProps = blocksGetStaticProps[typename]
        if (!getStaticProps) {
          return undefined
        }

        return [
          typename,
          await getStaticProps({
            siteId,
            locale: locale as SiteLocale,
            block,
          }),
        ] as const
      }),
    )
  )
    .filter(notEmpty)
    .reduce(
      (obj, [typename, props]) => ({
        ...obj,
        [typename]: props,
      }),
      {},
    )

  return {
    props: stripUndefined({
      siteConfig,
      layout,
      page,
      blockData,
      breadcrumbs,
    }),
    // TODO: Configure via environment variables
    revalidate: 60,
  }
}

export const getPageTitle = (page: Page) =>
  page.seo?.title ||
  // A clean fallback despite the hacky solution
  page.content.reduce<string | undefined>((title, section) => {
    if (title) {
      return title
    }

    if (section.__typename === 'TextWithImageHeaderRecord') {
      return section.title || undefined
    }

    return title
  }, undefined)

interface Props extends StaticProps {
  canonical?: boolean
  alternates?: boolean
}

const PageResolver = ({
  breadcrumbs,
  siteConfig,
  layout,
  page,
  blockData,
  canonical = true,
  alternates = true,
}: Props) => (
  <Layout
    siteConfig={siteConfig}
    layout={layout}
    breadcrumbs={
      breadcrumbs
        ? [
            {
              key: 'homepage',
              href: '/',
              title: siteConfig.homepageName || 'Home',
            },
            ...breadcrumbs.map(({ path, page }) => ({
              key: `page-${path}`,
              href: `/${path}`,
              title: (page && getPageTitle(page)) || '?',
            })),
          ]
        : undefined
    }
  >
    <PageSeo
      page={page}
      canonical={canonical === false ? canonical : undefined}
      alternates={alternates}
    />

    <BlockZone components={blocks} content={page.content} data={blockData} />
  </Layout>
)

export default PageResolver
